import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'js-cookie'
import axios from 'axios'
/* eslint-disable no-shadow */
Vue.use(Vuex)

export const state = () => ({
  admin: false,
  adminId: null,
  user: null,
  clients: [],
  clientIDs: [],
  isProfilePage: false,
  menuIsActive: false,
  profileTab: 0,
  isExcVideoModalOpen: false,
  isCompleteWorkoutModalOpen: false,
  threeWeekProgressModal: false,
  exercises: [],
  fitnessProgram: [],
  progressChart: [],
  checkins: [],
  userInfo: {},
  userAvatar: null,
  nutritions: null,
  recipes: null,
  groceries: null,
  trainerNotes: null,
  measurements: null,
  levelOfFitness: null,
  workoutTypes: null,
  subscriptionStatus: null,
  subStatus: null,
  programsCategories: [],
  stripeProds: [],
  cart: [],
  nextInvoice: null,
  clientPrograms: [],
  clientPurchasedPrograms: [],
  selectedProgram: null,
  isProgramEmpty: true,
  selectedSessions: [],
  stripePrograms: {
    'online-training': 'prod_HiLCibjbYCzxJi',
    '4-week-fitnhealthy': 'prod_LiJ7qCMLOH4mOs',
    '4-week-fitnhealthy-level-2': 'prod_OEbDIyPup7JPBS',
    '4-week-fitnhealthy-level-3': 'prod_OEdq14XaDdXXHS',
    'nutrition-program': 'prod_LcQf6XPLf6QbWZ',
    '4-week-booty-program': 'prod_Odk1Vv7f8EqbpL'
  },
  tabs: [
    {
      title: 'News Feed',
      component: 'Feed'
    },
    {
      title: 'Fitness Program',
      component: 'FitnessProgram',
      adminOnly: false
    },
    {
      title: 'Workout Log',
      component: 'WorkoutLog',
      adminOnly: false
    },
    {
      title: 'Check In',
      component: 'CheckIn',
      adminOnly: false
    },
    {
      title: 'Nutrition Program',
      component: 'NutritionsProgram',
      adminOnly: false
    },
    {
      title: 'Before / After Photos',
      component: 'BeforeAfter',
      adminOnly: false
    },
    {
      title: 'Account Information',
      component: 'PersonalInformation',
      adminOnly: false
    },
    {
      title: 'Measurements',
      component: 'Measurements',
      adminOnly: true
    },
    {
      title: 'Level of fitness',
      component: 'LevelOfFitness',
      adminOnly: true
    },
    {
      title: 'Trainer Notes',
      component: 'TrainerNotes',
      adminOnly: true
    }
  ]
})

export const actions = {
  async login({ commit }, account) {
    // Login the user
    const user = await this.$fireAuth
      .signInWithEmailAndPassword(account.email, account.password)
    // Get JWT from Firebase
    const token = await this.$fireAuth.currentUser.getIdToken()
    // Set JWT to the cookie
    Cookie.set('access_token', token, { expires: 7, sameSite: 'strict' })
    commit('SET_USER', user.user.uid)
  },

  async loginWithGoogle({ commit }) {
    // Login the user
    const provider = new this.$fireAuthObj.GoogleAuthProvider()
    provider.addScope('profile')
    provider.addScope('email')
    await this.$fireAuth.signInWithPopup(provider).then((result) => {
      // This gives you a Google Access Token.
      const token = result.credential.accessToken
      Cookie.set('access_token', token, { expires: 7, sameSite: 'strict' })
      commit('SET_USER', result.user.uid)
    })
  },

  async logout({ commit }) {
    await this.$fireAuth.signOut()
    commit('SET_USER', null)
    commit('SET_ADMIN', false)
    Cookie.remove('access_token')
    Cookie.remove('isAdmin')
    localStorage.removeItem('clientId')
  },

  async getClientsData({ commit, state }) {
    const clientsArr = []
    try {
      const clientsDocs = await this.$fireStore.collection('clients-information').get()
      for (let cl = 0; cl < clientsDocs.docs.length; cl += 1) {
        // exclude admin from clients array
        if (clientsDocs.docs[cl].id !== state.adminId) {
          const clientObj = {
            id: clientsDocs.docs[cl].id,
            ...clientsDocs.docs[cl].data()
          }
          clientsArr.push(clientObj)
        }
      }
    } catch (error) {
      console.log(`getClientsData: ${error}`)
    }
    commit('SET_CLIENTS', clientsArr)
  },

  async getExercises({ commit }) {
    const exercises = []
    this.$fireStore.collection('exercises')
      .get().then((doc) => {
        for (let ex = 0; ex < doc.docs.length; ex += 1) {
          const excObj = {
            id: doc.docs[ex].id,
            val: doc.docs[ex].data()
          }
          exercises.push(excObj)
        }
      }).then(() => {
        commit('SET_EXERCISES', exercises)
      })
  },

  async getProgram({ commit }, params) {
    try {
      let fitnessProgram = null
      if (params.programId && params.programId !== 'custom-program') {
        fitnessProgram = await this.$fireStore.collection(`global-fitness-programs/${params.programId}/program`).orderBy('created', 'asc').get()
      } else {
        fitnessProgram = await this.$fireStore.collection(`clients-fitness-program/${params.userId}/program`).orderBy('created', 'asc').get()
      }

      const FitProgram = fitnessProgram.docs.map((fp) => ({
        [fp.id]: fp.data()
      }))

      commit('SET_FITNESS_PROGRAM', FitProgram)
    } catch (error) {
      console.log(`getProgram: ${error}`)
    }
  },

  async isProgramEmpty({ commit }, clientId) {
    try {
      const customProgram = await this.$fireStore
        .collection(`clients-fitness-program/${clientId}/program`)
        .limit(1)
        .get()
      let isEmpty = true
      if (customProgram.size !== 0) {
        isEmpty = false
      }
      commit('SET_IS_PROGRAM_EMPTY', isEmpty)
    } catch (error) {
      console.log(error)
    }
  },

  async getUserInfo({ commit }, userId) {
    const userInfo = {}
    const doc = await this.$fireStore.collection('clients-information').doc(userId).get()
    if (doc.exists) {
      const data = doc.data()
      userInfo.first_name = data.first_name
      userInfo.last_name = data.last_name
      userInfo.email = data.email
      userInfo.phone = data.phone
      userInfo.weight = data.weight
      userInfo.height = data.height
      userInfo.injuries = data.injuries
      userInfo.medications = data.medications
      userInfo.supplements = data.supplements
      userInfo.occupation = data.occupation
      userInfo.areaOfFocus = data.areaOfFocus
      userInfo.activityLevel = data.activityLevel
      userInfo.sessionsPerWeek = data.sessionsPerWeek
      userInfo.currentDietPlan = data.currentDietPlan
      userInfo.areasForImprovement = data.areasForImprovement
      userInfo.planType = data.planType
      userInfo.termsConditions = data.termsConditions
      // userInfo.fitnessWaiver = data.fitnessWaiver;
      userInfo.stripeCustomerId = data.stripeCustomerId
      userInfo.stripeProductId = data.stripeProductId
      userInfo.stripeSubscriptionId = data.stripeSubscriptionId
      userInfo.stripePaymentInfo = data.stripePaymentInfo
      userInfo.subscriptionType = data.subscriptionType
      userInfo.purchasedProducts = data.purchasedProducts

      userInfo.gender = data.gender
      userInfo.goal = data.goal
      userInfo.rawDate = data.date_of_birth
      const getDate = new Date(data.date_of_birth)
      const addedDayToDate = getDate.setDate(getDate.getDate() + 1)
      userInfo.date_of_birth = data.date_of_birth ? new Date(addedDayToDate).toDateString() : null
    }
    commit('SET_USER_INFO', userInfo)
  },

  async getNutritions({ commit }, userId) {
    const doc = await this.$fireStore.collection('clients-nutritions').doc(`${userId}`)
      .get()
    if (doc.data()) {
      commit('SET_NUTRITIONS', doc.data().nutritions)
    }
  },

  async getRecipes({ commit }, userId) {
    const doc = await this.$fireStore.collection('clients-recipes').doc(`${userId}`)
      .get()
    if (doc.data()) {
      commit('SET_RECIPES', doc.data().recipes)
    }
  },

  async getGroceries({ commit }, userId) {
    const doc = await this.$fireStore.collection('clients-groceries').doc(`${userId}`)
      .get()
    if (doc.data()) {
      commit('SET_GROCERIES', doc.data().groceries)
    }
  },

  async getTrainerNotes({ commit }, userId) {
    try {
      const doc = await this.$fireStore.collection('trainer-notes').doc(`${userId}`)
        .get()
      if (doc.data()) {
        commit('SET_TRAINER_NOTES', doc.data().trainerNotes)
      }
    } catch (err) {
      console.log(err)
    }
  },

  async getMeasurements({ commit }, userId) {
    const measurementsRef = this.$fireStore.collection(`clients-fitness-level/${userId}/measurements`)
    try {
      const firstMeasurement = await measurementsRef.limit(1).get()
      const lastMeasurement = await measurementsRef.orderBy('date', 'desc').limit(1).get()

      if (firstMeasurement.docs.length && lastMeasurement.docs.length) {
        const measurements = {
          first: firstMeasurement.docs[0].data(),
          last: lastMeasurement.docs[0].data()
        }
        commit('SET_MEASUREMENTS', measurements)
      }
    } catch (err) {
      console.log(err)
    }
  },

  async getLevelOfFitness({ commit }, userId) {
    const lvlOfFitnessRef = this.$fireStore.collection(`clients-fitness-level/${userId}/level-of-fitness`)
    try {
      const firstLvlOfFitness = await lvlOfFitnessRef.limit(1).get()
      const lastLvlOfFitness = await lvlOfFitnessRef.orderBy('date', 'desc').limit(1).get()

      if (firstLvlOfFitness.docs.length && lastLvlOfFitness.docs.length) {
        const lvlOfFitness = {
          first: firstLvlOfFitness.docs[0].data(),
          last: lastLvlOfFitness.docs[0].data()
        }
        commit('SET_LEVELOFFITNESS', lvlOfFitness)
      }
    } catch (err) {
      console.log(err)
    }
  },

  async getCheckins({ commit }, userId) {
    const doc = await this.$fireStore.collection(`clients-checkin/${userId}/checkins`).get()
    const progress = doc.docs.map((e) => ({
      id: e.id,
      data: {
        bodyFat: e.data().bodyFat,
        weight: e.data().weight
      }
    }))
    const docReverse = [...doc.docs.reverse()]
    const checkins = docReverse.map((e) => ({
      id: e.id,
      data: e.data()
    }))
    commit('SET_CHART', progress)
    commit('SET_CHECKINS', checkins)
  },

  async getDayWorkoutType({ commit }, payload) {
    const workoutTypes = []
    let doc

    if (payload.route === '/fitness-program-manager' || payload.route === '/my-programs') {
      doc = await this.$fireStore.collection(`global-fitness-programs/${payload.programId}/workoutTypes`)
        .get()
    } else {
      doc = await this.$fireStore.collection(`clients-fitness-program/${payload}/workoutTypes`)
        .get()
    }

    if (doc) {
      for (let i = 0; i < doc.docs.length; i += 1) {
        workoutTypes.push(doc.docs[i].data())
      }
      commit('SET_DAY_WORKOUT_TYPE', workoutTypes)
    }
  },

  async isSubscriptionActive({ commit, state }) {
    if (state.userInfo.stripeSubscriptionId) {
      axios
        .post(`${process.env.API_URL}/isSubscriptionActive`, {
          sub_id: state.userInfo.stripeSubscriptionId
        })
        .then((scess) => {
          const { cancel_at_period_end: cancelAtPerEnd, cancel_at: cancelAt } = scess.data
          const cancelAtTimestamp = cancelAtPerEnd && cancelAt
          const date = new Date(cancelAtTimestamp * 1000)
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          const day = date.getDate()
          const month = months[date.getMonth()]
          const year = date.getFullYear()
          commit('SET_SUB_STATUS', scess.data.status)
          if ((cancelAtPerEnd || cancelAt) && scess.data.status !== 'canceled') {
            commit('SET_SUBSCRIPTION_STATUS', `${scess.data.status} until ${month} ${day}, ${year}`)
          } else {
            commit('SET_SUBSCRIPTION_STATUS', scess.data.status)
          }
        }).catch((error) => {
          console.log('isSubscriptionActive: ', error)
        })
    }
  },

  async clientNextInvoice({ commit, state }) {
    if (state.userInfo.stripeCustomerId && state.userInfo.stripeSubscriptionId) {
      axios
        .post(`${process.env.API_URL}/clientNextInvoice`, {
          cusId: state.userInfo.stripeCustomerId,
          subId: state.userInfo.stripeSubscriptionId
        })
        .then((scess) => {
          commit('SET_NEXT_INVOICE', scess.data)
        }).catch((error) => {
          console.log(error)
        })
    } else {
      commit('SET_NEXT_INVOICE', null)
    }
  },

  async getFitnessProgramsCategories({ commit }) {
    const categories = await this.$fireStore.collection('global-progrms-categories').get()

    const categoriesArr = []
    if (categories.docs.length) {
      for (let i = 0; i < categories.docs.length; i += 1) {
        /* eslint-disable-next-line */
        const catPrograms = await this.$fireStore.collection(`global-progrms-categories/${categories.docs[i].id}/fitnessPrograms`).get()
        const programs = catPrograms.docs.map((pr) => pr.data())

        categoriesArr.push({
          name: categories.docs[i].data().programCategory,
          catId: categories.docs[i].id,
          programs
        })
      }
      commit('SET_PROGRAM_CATEGORIES', categoriesArr)
    }
  },

  async getProductsStripe({ commit }) {
    const productsWithPrices = []
    const productsDocs = await this.$fireStore
      .collection('product')
      .where('type', '==', 'service')
      .where('active', '==', true)
      .get()

    const products = productsDocs.docs.map((doc) => doc.data())
    for (let i = 0; i < products.length; i++) {// eslint-disable-line
      const pricesDocs = await this.$fireStore// eslint-disable-line
        .collection('price')
        .where('product', '==', products[i].id)
        .where('active', '==', true)
        .get()

      const prices = pricesDocs.docs.map((doc) => doc.data())

      const oneTimeProds = prices.filter((price) => price.type === 'one_time')
      productsWithPrices.push({
        product: products[i],
        // Current setup - all product should have only one active price
        price: prices[0],
        one_time_prices: oneTimeProds
      })
    }
    commit('SET_STRIPE_PRODS', productsWithPrices)
  },

  async getSingleProductStripe({ commit }, program) {
    const product = await this.$fireStore.collection('product').doc(program).get()
    const price = await this.$fireStore.collection('price').where('product', '==', program).get()
    return commit('ADD_CART', { product: product.data(), price: price.docs[0].data() })
  },

  async getUserPrograms({ commit }, userId) {
    try {
      const purchasedPrograms = await this.$fireStore.collection(`clients-purchased-programs/${userId}/programs`).get()
      commit('SET_USER_PURCHASED_PROGRAMS', purchasedPrograms.docs.map((doc) => doc.data()))
      if (purchasedPrograms.size) {
        const progIDArr = purchasedPrograms.docs.map((doc) => doc.id)

        const programsContentSnapshot = await this.$fireStore.collection('global-fitness-programs').where(this.$fireStoreObj.FieldPath.documentId(), 'in', progIDArr).get()
        const progs = programsContentSnapshot.docs.map((doc) => ({
          progId: doc.id,
          progMeta: doc.data()
        }))

        commit('SET_USER_PROGRAMS', progs)
      }
    } catch (err) {
      console.log(err)
    }
  },

  async getListOfUserIds({ commit }, userId) {
    try {
      const clientIds = await axios
        .post(`${process.env.API_URL}/getListOfClientsIds`, { userId })

      commit('SET_CLIENT_IDS', clientIds.data)
    } catch (error) {
      console.log(error)
    }
  },

  async bookSessions({ commit }, sessions) {
    commit('SET_SELECTED_SESSIONS', sessions)
  }
}

export const getters = {
  noScroll: (state) => {
    const body = document.querySelector('body')
    if (!state) {
      body.classList.add('modal_active')
    } else {
      body.classList.remove('modal_active')
    }
  }
}

export const mutations = {
  SET_USER_AVATAR(state, data) {
    state.userAvatar = data
  },
  SET_CLIENT_IDS(state, data) {
    state.clientIDs = data
  },
  SET_USER_PURCHASED_PROGRAMS(state, data) {
    state.clientPurchasedPrograms = data
  },
  SET_SELECTED_SESSIONS(state, data) {
    state.selectedSessions = data
  },
  SET_SELECTED_PROGRAM(state, data) {
    state.selectedProgram = data
  },
  SET_IS_PROGRAM_EMPTY(state, data) {
    state.isProgramEmpty = data
  },
  SET_USER_PROGRAMS(state, data) {
    state.clientPrograms = data
  },
  SET_NEXT_INVOICE(state, data) {
    state.nextInvoice = data
  },
  ADD_CART(state, data) {
    state.cart = [data]
  },
  SET_STRIPE_PRODS(state, data) {
    state.stripeProds = data
  },
  SET_CLIENTS(state, data) {
    state.clients = data
  },
  ON_AUTH_STATE_CHANGED_MUTATION: (state, { authUser, claims }) => {
    if (authUser) {
      const { uid } = authUser
      const { admin } = claims
      if (admin) {
        state.adminId = uid
        Cookie.set('isAdmin', true, { expires: 7, sameSite: 'strict' })
      } else if (process.browser) {
        localStorage.setItem('clientId', uid)
      }
      state.user = uid
      state.admin = admin
    }
  },
  SET_PROGRAM_CATEGORIES(state, data) {
    state.programsCategories = data
  },
  SET_SUBSCRIPTION_STATUS(state, data) {
    state.subscriptionStatus = data
  },
  SET_SUB_STATUS(state, data) {
    state.subStatus = data
  },
  SET_DAY_WORKOUT_TYPE(state, data) {
    state.workoutTypes = data
  },
  SET_ISPROFILEPAGE(state, data) {
    state.isProfilePage = data
  },
  SET_LEVELOFFITNESS(state, data) {
    state.levelOfFitness = data
  },
  SET_MEASUREMENTS(state, data) {
    state.measurements = data
  },
  SET_NUTRITIONS(state, data) {
    state.nutritions = data
  },
  SET_RECIPES(state, data) {
    state.recipes = data
  },
  SET_GROCERIES(state, data) {
    state.groceries = data
  },
  SET_TRAINER_NOTES(state, data) {
    state.trainerNotes = data
  },
  SET_USER_INFO(state, data) {
    state.userInfo = data
  },
  SET_CHECKINS(state, data) {
    state.checkins = data
  },
  SET_CHART(state, data) {
    state.progressChart = data
  },
  SET_FITNESS_PROGRAM(state, data) {
    state.fitnessProgram = data
  },
  SET_EXERCISES(state, data) {
    state.exercises = data
  },
  SET_PROFILETAB(state, data) {
    state.profileTab = data
    if (process.browser) {
      window.scrollTo({
        top: 0,
        left: 0
      })
    }
  },
  SET_ADMIN(state, data) {
    state.admin = data
  },
  SET_USER(state, data) {
    state.user = data
  },
  setVideoModalState(state) {
    getters.noScroll(state.isExcVideoModalOpen)
    state.isExcVideoModalOpen = !state.isExcVideoModalOpen
  },
  completeWorkoutModal(state) {
    getters.noScroll(state.isCompleteWorkoutModalOpen)
    state.isCompleteWorkoutModalOpen = !state.isCompleteWorkoutModalOpen
  },
  threeWeekProgressModal(state) {
    state.threeWeekProgressModal = !state.threeWeekProgressModal
  },
  setMenuState(state, menuIsActive) {
    state.menuIsActive = menuIsActive
  },
  toggleMenuState(state) {
    state.menuIsActive = !state.menuIsActive
  }
}
